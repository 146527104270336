﻿import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKDialog from '@tk/components/tk.dialog';
import { Editor, RawEditorOptions } from 'tinymce';

interface RequestData extends Record<string, string | undefined> {
    type: string;
    boid?: string;
    bonumber?: string;
    arttextno?: string;
    per?: string;
    template?: string;
    modalid?: string;
    html?: string;
    bo?: string;
    attr?: string;
    id?: string;
}

interface ResponseJsonData {
    title: string;
    description: string;
    url: string;
}

interface TemplateEntry {
    title: string;
    description: string;
    content: string;
}

export default class TKStaticContent extends TKCustomElementFactory {
    button?: HTMLButtonElement;
    requestData?: RequestData;
    dialog?: HTMLDivElement;
    urlTemplate: Array<TemplateEntry>;
    templateContentPromiseArray: Array<Promise<void>>;
    templatesLoaded: boolean;
    fileUploadIFrameURL: string;

    protected target: string;
    protected boId?: string;
    protected boNumber?: string;
    protected artTextNumber?: string;
    protected langNumber?: string;
    protected dataPer?: string;
    protected asyncTemplate?: string;
    protected bo?: string;
    protected attr?: string;
    protected itemId?: string;

    constructor() {
        super();
        this.button = this.querySelector('[data-tk-static-content-edit]') || undefined;

        this.target = this.getAttribute('data-tk-target') || '#tk-static-content-editor';
        this.boId = this.getAttribute('data-tk-bo-id') || undefined;
        this.boNumber = this.getAttribute('data-tk-bo-number') || undefined;
        this.artTextNumber = this.getAttribute('data-tk-art-text-number') || undefined;
        this.bo = this.getAttribute('data-tk-bo') || undefined;
        this.attr = this.getAttribute('data-tk-attr') || undefined;
        this.itemId = this.getAttribute('data-tk-id') || undefined;
        this.langNumber = this.getAttribute('data-tk-lang-number') || undefined;
        this.dataPer = this.getAttribute('data-tk-data-per') || undefined;
        this.asyncTemplate = this.getAttribute('data-tk-async-template') || undefined;
        this.urlTemplate = [];
        this.templateContentPromiseArray = [];
        this.templatesLoaded = false;
        this.fileUploadIFrameURL = this.getAttribute('data-tk-file-upload-iframe-url') || '';
    }

    async connectedCallback() {
        if (
            !this.button
            || !this.boId
            || !this.dataPer
            || !this.asyncTemplate
        ) return;
        // @ts-ignore Import module
        // eslint-disable-next-line import/extensions, import/no-absolute-path
        await import('/webportal/config/layout/themes/tk/vendor/tinymce/tinymce.min.js');
        this.registerClickListener();
    }

    registerClickListener() {
        const onClick = this.sendAjaxRequest.bind(this);
        this.pushListener({ event: 'click', element: this.button!, action: onClick });
    }

    registerModalListener(modal: HTMLElement) {
        const saveButton = modal.querySelector<HTMLButtonElement>('[data-tk-static-content-save]') || undefined;
        const onSave = this.save.bind(this, saveButton);
        saveButton && this.pushListener({ event: 'click', element: saveButton, action: onSave });

        const cancelButton = modal.querySelector('[data-tk-static-content-cancel]');
        const onCancel = this.cancel.bind(this);
        cancelButton && this.pushListener({ event: 'click', element: cancelButton, action: onCancel });
    }

    sendAjaxRequest() {
        this.requestData = {
            type: 'staticcontent',
            boid: this.boId,
            bonumber: this.boNumber,
            arttextno: this.artTextNumber,
            per: this.dataPer!,
            template: this.asyncTemplate!,
            modalid: this.target,
            bo: this.bo,
            id: this.itemId,
            attr: this.attr,
        };
        const url = setAsyncUrl(true);
        fetchRequest<RequestData>({
            requestURL: url,
            resolveHandler: this.showContentInEditMode.bind(this),
            payload: this.requestData,
        });
    }

    save(button?: HTMLButtonElement) {
        if (!button) return;
        const htmlEncoded = TKStaticContent.htmlEncode(TKStaticContent.getContentFromEditMode());
        this.requestData = {
            type: 'staticcontent',
            boid: this.boId,
            bonumber: this.boNumber,
            arttextno: this.artTextNumber,
            bo: this.bo,
            id: this.itemId,
            attr: this.attr,
            html: htmlEncoded,
        };

        const url = setAsyncUrl();
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshContent.bind(this),
            payload: this.requestData,
        });
        this.cancel();
    }

    cancel() {
        TKDialog.close(this.dialog!);
    }

    showContentInEditMode(response: TKResponse) {
        if (!response.success || !this.requestData) return;

        this.dialog = TKDialog.renderDialog<HTMLDivElement>({
            content: response.dataAsHtml,
            className: 'tk-dialog tk-dialog--fullscreen',
            tag: 'div',
        });
        TKDialog.open(this.dialog);
        this.pushListener({ event: 'close', element: this.dialog, action: () => this.dialog?.remove() });

        this.initEditor();

        const modal = document.querySelector<HTMLElement>(this.target);
        if (!modal) return;
        this.registerModalListener(modal);
    }

    static getContentFromEditMode() {
        return window.tinymce.activeEditor?.getContent({ format: 'raw' }) || '';
    }

    refreshContent(response: TKResponse) {
        if (!response.success || !this.requestData) return;
        let contentContainer = document.getElementById(
            `staticcontent-${this.requestData.boid}-${this.requestData.arttextno}`,
        );
        if (!contentContainer) {
            this.requestData.id !== undefined && (
                contentContainer = document.getElementById(this.requestData.id)
            );
        } else {
            contentContainer.innerHTML = response.dataAsHtml;
        }
    }

    initEditor() {
        if (!this.requestData) return;
        const selector = `${this.requestData.modalid} [data-tk-static-content-textarea]`;
        // Editor must be removed before initialisation
        window.tinymce.remove(selector);
        const options = {
            selector,
            language: TKStaticContent.getLanguage(),
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image',
                'charmap', 'preview', 'anchor', 'pagebreak', 'searchreplace',
                'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                'media', 'nonbreaking', 'save', 'table', 'directionality',
                'emoticons', 'template', 'quickbars',
            ],
            insertdatetime_formats: ['%d.%m.%Y', '%H:%M'],
            contextmenu: 'link image table',
            tools: 'inserttable',
            toolbar:
                'undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter'
                + 'alignright alignjustify | bullist numlist outdent indent | image media link | '
                + 'preview print | filemanager',
            width: '100%',
            base_url: '/webportal/config/layout/themes/tk/vendor/tinymce/',
            language_url: `/webportal/config/layout/themes/tk/translations/tinymce/${TKStaticContent.getLanguage()}.js`,
            promotion: false,
            content_css: [
                'default',
                `/webportal/config/layout/themes/project/generated/css/staticcontent.css?${new Date().getTime()}`,
            ],
            templates: this.urlTemplate,
            extended_valid_elements: 'tk-tabs-accordion,tk-tabs-accordion[class],i[data-tk-accordion-icon]',
            custom_elements: 'tk-tabs-accordion,tk-tabs-accordion',
            setup: (editor) => {
                editor.on('SaveContent', (event) => {
                    event.content = TKStaticContent.htmlEncode(TKStaticContent.htmlDecode(event.content));
                });

                const openmanager = () => {
                    editor.windowManager.openUrl({
                        title: 'File Manager',
                        url: TKStaticContent.getFileManagerURL(editor),
                        width: 900,
                        height: 440,
                    });
                };

                editor.ui.registry.addButton('filemanager', {
                    icon: 'browse',
                    tooltip: 'Insert file',
                    onAction: openmanager,
                });

                editor.ui.registry.addMenuItem('filemanager', {
                    icon: 'browse',
                    text: 'Insert file',
                    onAction: openmanager,
                });

                const templateURLElement = document.querySelector('[data-tk-staticcontent-templates-url]');
                const templateURL = templateURLElement && templateURLElement.getAttribute('data-op-url');

                if (templateURL) {
                    this.getTemplates(templateURL);
                }
            },
        } as RawEditorOptions;

        if (this.fileUploadIFrameURL) options.file_picker_callback = this.handleFilePicker.bind(this);

        window.tinymce.init(options);
    }

    handleFilePicker(
        callback: (value: string, meta?: Record<string, unknown>) => void,
    ) {
        window.tinymce.activeEditor?.windowManager.openUrl({
            title: 'Fileupload Area',
            url: this.fileUploadIFrameURL,
            width: 800,
            height: 600,
        });

        window.addEventListener('message', (event) => {
            const { data } = event;
            if (window.location.origin !== event.origin) return;
            callback(data.content);
        });
    }

    static getFileManagerURL(editor: Editor) {
        return `/webportal/filemanager.aspx?editor=${editor.id}&lang=${TKStaticContent.getLanguage()}`;
    }

    static htmlEncode(content: string) {
        return content.toString().replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    static htmlDecode(content: string) {
        return content.toString().replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&quot;/g, '"')
            .replace(/&amp;/g, '&');
    }

    static getLanguage() {
        if (window.location.href.indexOf('/en/') !== -1) {
            return 'en_GB';
        } if (window.location.href.indexOf('/fr/') !== -1) {
            return 'fr_FR';
        } if (window.location.href.indexOf('/it/') !== -1) {
            return 'it';
        }
        return 'de';
    }

    async getTemplates(templateURL: string) {
        if (this.templatesLoaded) return;
        // get url content
        return new Promise((resolve) => {
            fetchRequest<RequestData>({
                requestURL: templateURL,
                resolveHandler: (response: TKResponse<RequestData>) => {
                    this.getTemplateContent(response, resolve);
                },
            });
        });
    }

    getTemplateContent(response: TKResponse, resolveFunction: (data: void) => void) {
        (response.dataAsJson as Array<ResponseJsonData>).forEach((element) => {
            this.templateContentPromiseArray = [];
            this.templateContentPromiseArray.push(
                new Promise((resolve) => {
                    fetchRequest<RequestData>({
                        requestURL: element.url,
                        resolveHandler: (response: TKResponse<RequestData>) => {
                            this.saveTemplateContent(response, element, resolveFunction, resolve);
                        },
                    });
                }),
            );
        });
    }

    saveTemplateContent(
        response: TKResponse,
        element: ResponseJsonData,
        resolvedAllFunction: (data: void) => void,
        resolveArrayElementFunction: (data: void) => void,
    ) {
        const templateContent = response.dataAsHtml;

        const newTemplateEntry = {
            title: element.title,
            description: element.description,
            content: templateContent,
        };

        this.urlTemplate.push(newTemplateEntry);
        resolveArrayElementFunction();

        Promise.allSettled(this.templateContentPromiseArray).then(() => {
            resolvedAllFunction();
            this.templatesLoaded = true;
        });
    }
}
