﻿import { PriceData, ResponseItem } from '@tk/components/tk.article.list';
import TKPrice from '@tk/components/tk.price';
import isBase64 from '@tk/utilities/tk.base64';
import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';

const QUANTITY_ATTR = 'data-quantity';
const READY_TO_RUN_ATTR = 'data-tk-ready-to-run';

interface DiscountResponseItem extends ResponseItem {
    basicPrice: number;
    servicePrice: number;
}

export default class TrisaPrice extends TKPrice {
    quantity?: number;
    discountWrapper?: HTMLElement;
    discountElement?: HTMLElement;
    discountAction?: HTMLElement;
    readyToRun?: boolean;

    static observedAttributes = [
        QUANTITY_ATTR,
        READY_TO_RUN_ATTR,
    ];

    constructor() {
        super();

        this.quantity = Number(this.getAttribute(QUANTITY_ATTR)) || undefined;
        this.discountWrapper = document.querySelector('[data-tk-discount-wrapper]') || undefined;
        this.discountElement = this.discountWrapper?.querySelector('[data-tk-discount-value]') || undefined;
        this.discountAction = this.discountWrapper?.querySelector('[data-tk-discount-action]') || undefined;
        this.articleList = this.closest('[data-tk-article-list]') || undefined;
        this.readyToRun = this.getAttribute(READY_TO_RUN_ATTR) === 'true';
    }

    connectedCallback(): void {
        if (this.hasAttribute(READY_TO_RUN_ATTR) && !this.readyToRun) return;
        super.connectedCallback();
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === QUANTITY_ATTR) {
            const quantity = Number(newValue);
            this.quantity = quantity;
            this.handlePrice();
        } else if (name === READY_TO_RUN_ATTR && newValue === 'true') {
            super.connectedCallback();
        }
    }

    handlePrice() {
        if (!this.item) return;
        const items = this.asyncURL === setAsyncUrl(true)
            ? TKPrice.getItemsAsJSONString([this.item])
            : TKPrice.getItemsAsEntrypoint([this.item]);

        const data = {
            type: 'price',
            ...items,
        } as Record<string, string>;

        if (this.quantity) data.quantity = String(this.quantity);

        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.handleResponse.bind(this),
            payload: data,
        });
    }

    refreshPrice(item: PriceData, responseItem: DiscountResponseItem) {
        const conditions = [
            {
                condition: responseItem.basispriceisset,
                selector: '[data-tk-price-basic]',
                className: undefined,
            },
            {
                condition: responseItem.servicepriceisset,
                selector: '[data-tk-price-service]',
                className: this.isServiceClassName,
            },
            {
                condition: responseItem.promopriceisset,
                selector: '[data-tk-price-promotion]',
                className: this.isPromotionClassName,
            },
        ];
        const { selector, className } = conditions.find(({ condition }) => condition) || {};
        if (!selector) return;
        const { html, basicPrice, servicePrice } = responseItem;
        if (!html) return;
        const htmlString = isBase64(html) ? atob(html) : html;
        const pricesElement = render(htmlString, true);
        conditions.forEach((condition) => {
            const priceElement = pricesElement.querySelector(condition.selector);
            const priceElementWrapper = this.querySelector(condition.selector);
            if (!priceElement || !priceElementWrapper) return;
            priceElementWrapper.outerHTML = priceElement.outerHTML;
        });
        className && this.classList.add(className);

        if (
            !this.discountWrapper
            || !this.discountElement
            || !this.discountAction
            || basicPrice - servicePrice === 0
        ) return;
        this.discountAction.hidden = false;
        const discount = Math.round(((basicPrice - servicePrice) / basicPrice) * 100);
        this.discountElement.textContent = `${discount}%`;
    }
}