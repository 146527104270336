﻿import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TrisaElabel extends TKCustomElementFactory {
    asyncURL: string;
    eLabel: string;

    constructor() {
        super();
        this.asyncURL = this.getAttribute('data-trisa-async-url');
        this.eLabel = this.firstChild;
        if (this.asyncURL) {
            this.getData();
        }
    }

    getData() {
        this.addEventListener('mouseover', (event) => {
            if (event.target.closest('[data-trisa-src]')
                && !event.target.closest('[data-trisa-src]').classList.contains('visited')) {
                fetchRequest({
                    requestURL: this.asyncURL,
                    resolveHandler: this.handleResponse.bind(this),
                });
            } else {
                this.querySelector('[data-trisa-dest]').classList.remove('hidden');
            }
        });
        this.addEventListener('mouseout', (event) => {
            if (event.target.closest('[data-trisa-src]') || event.target.closest('[data-trisa-dest]')) {
                this.querySelector('[data-trisa-dest]')?.classList.add('hidden');
            }
        });
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsHtml) {
            const destElem = this.querySelector('[data-trisa-dest]');
            const srcElem = this.querySelector('[data-trisa-src]');
            if (srcElem?.classList.contains('visited')) {
                destElem?.classList.add('hidden');
            } else {
                destElem.innerHTML = response.dataAsHtml;
                srcElem?.classList.add('visited');
                destElem?.classList.remove('hidden');
            }
        }
    }

    handleError(error: string) {
        throw new Error(error);
    }
}