﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TRISAMessage extends TKCustomElementFactory {
    closeButtons?: NodeListOf<HTMLButtonElement>;

    constructor() {
        super();

        this.closeButtons = this.querySelectorAll('[data-trisa-close]') || undefined;
    }

    connectedCallback(): void {
        this.registerClickListener();
        this.registerTransitionListener();
    }

    registerClickListener() {
        this.closeButtons?.forEach((button) => {
            this.pushListener({
                event: 'click',
                element: button,
                action: this.clickAction.bind(this),
            });
        });
    }

    registerTransitionListener() {
        this.pushListener({
            event: 'transitionend',
            element: this,
            action: this.transitionEndAction.bind(this),
        });
    }

    clickAction() {
        // blendet die Komponente aus
        this.classList.add('tk-message--hidden');
    }

    transitionEndAction(event:TransitionEvent) {
        // entfernt die Komponente aus dem DOM, nachdem die Transition zu Ende ist
        if (event.propertyName === 'margin-bottom') {
            this.remove();
            // hier geht es weiter, sobald bekannt ist, was mit der Box nach dem Schliessen passieren soll.
        }
    }
}