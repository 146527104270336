﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TrisaHeader extends TKCustomElementFactory {
    header?: HTMLElement;
    disableScrolldownBreakpoint?: Breakpoint;
    minWidthBreakpoint: number;
    elementToScrollWith?: HTMLElement;

    protected lastScrollPosY: number = 0;
    protected maxHeightScrollWith: number;
    protected scrollWay: number = 0;
    protected scrollDeltaY: number;

    constructor() {
        super();

        this.header = this.querySelector('header') || undefined;
        if (!this.header) throw new Error('Header: Header is missing!');
        this.disableScrolldownBreakpoint = (
            this.getAttribute('data-tk-disable-scrolldown-breakpoint') as Breakpoint
        ) || 's';
        this.minWidthBreakpoint = window.viewport.breakpoints.get(this.disableScrolldownBreakpoint) || 0;
        this.elementToScrollWith = this.querySelector('[data-tk-to-scroll-with]') || undefined;
        if (!this.elementToScrollWith) {
            // throw new Error('Header: Element to scroll with is missing! Use the [data-tk-to-scroll-with] selector.');
        }

        this.maxHeightScrollWith = this.getMaxHeight();
        this.scrollDeltaY = Number(this.getAttribute('data-tk-scroll-delta-y')) || 1;
    }

    connectedCallback(): void {
        this.registerViewportListener();
        // window.viewport.clientWidth < this.minWidthBreakpoint && this.registerScrollListener();
        this.registerScrollListener();
    }

    registerScrollListener(): void {
        if (!this.elementToScrollWith) return;
        const onScrolldown = this.handleScrolldown.bind(this);
        this.maxHeightScrollWith = this.getMaxHeight();
        this.pushListener({
            event: 'scroll',
            element: window,
            action: onScrolldown,
        });
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        this.pushListener({
            event: 'tk-viewport-resize',
            element: window.viewport,
            action: onViewportResize,
        });
    }

    handleViewportResize(event: Event): void {
        const customEvent = event as CustomEvent;
        if (customEvent.detail.width < this.minWidthBreakpoint) {
            this.registerScrollListener();
        } else {
            this.removeListener(window);
        }
    }

    handleScrolldown(event: Event): void {
        // console.log('handleScrolldown:', this.header, this.lastScrollPosY);
        if (!(this.header && this.elementToScrollWith)) return;
        const { scrollY } = event.currentTarget as Window;

        if (scrollY > this.lastScrollPosY) {
            this.scrollWay = this.maxHeightScrollWith;
            this.header.closest('.viewport__header')?.classList.remove('tk-header--sticky');
            if (this.lastScrollPosY > 230) {
                this.header.closest('.viewport__header')?.setAttribute('style', 'top:-120px');
                this.header.closest('.viewport__header')?.classList.add('tk-header--move');
            }
            if (this.lastScrollPosY > 350) {
                this.header.closest('.viewport__header')?.removeAttribute('style');
            }
        } else {
            // console.log('4:', this.lastScrollPosY, scrollY, this.maxHeightScrollWith);
            this.scrollWay = 0;
            this.header.closest('.viewport__header')?.removeAttribute('style');
            if (this.lastScrollPosY <= 150) {
                this.header.closest('.viewport__header')?.classList.remove('tk-header--move');
            }
            this.header.closest('.viewport__header')?.classList.add('tk-header--sticky');
        }

        this.elementToScrollWith.style.transform = `translateY(-${this.scrollWay}px)`;
        this.lastScrollPosY = scrollY;
    }

    getMaxHeight(): number {
        if (!this.elementToScrollWith) return 0;
        const { paddingBottom } = window.getComputedStyle(this.elementToScrollWith);
        const { height } = this.elementToScrollWith.getBoundingClientRect();
        return height - parseInt(paddingBottom, 10);
    }
}
